import { Ref } from 'vue';
import Item from '@/domain/Item';
import ItemSearchable from '@/dtos/filters/ItemSearchable';
import ItemService from '@/services/ItemService';
import ItemSearchStringFilter from '@/domain/filters/ItemSearchStringFilter';

export interface UseItemSearch {
    findItemsByName: (search: string) => Promise<Array<Item>>;
    selectItem: (item: Item | null) => void;
}

export default function useItemSearch<T extends ItemSearchable>(modelValue: Ref<T>): UseItemSearch {
    const itemService = new ItemService();

    function findItemsByName(search: string): Promise<Array<Item>> {
        const searchFilter = new ItemSearchStringFilter({
            searchString: search,
        });
        return itemService.findItemsByName(searchFilter);
    }

    function selectItem(item: Item | null) {
        modelValue.value.item = item;
    }

    return {
        findItemsByName,
        selectItem,
    };
}
