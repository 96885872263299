import { toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, createTextVNode as _createTextVNode, createBlock as _createBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-4488ae2c"),n=n(),_popScopeId(),n)
const _hoisted_1 = {
  key: 0,
  style: {"color":"red"}
}
const _hoisted_2 = { key: 1 }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_date_input = _resolveComponent("date-input")!
  const _component_filter_criteria = _resolveComponent("filter-criteria")!
  const _component_form_multi_select = _resolveComponent("form-multi-select")!
  const _component_dropdown_autocomplete_single_select = _resolveComponent("dropdown-autocomplete-single-select")!
  const _component_advanced_filters = _resolveComponent("advanced-filters")!

  return (_openBlock(), _createBlock(_component_advanced_filters, {
    "filter-default": _ctx.filterDefault,
    "filter-dirty": _ctx.filterDirty,
    onReset: _ctx.resetAllFilters,
    onSubmit: _ctx.submit
  }, {
    default: _withCtx(() => [
      _createVNode(_component_filter_criteria, null, {
        header: _withCtx(() => [
          (_ctx.invalidDateRange)
            ? (_openBlock(), _createElementBlock("div", _hoisted_1, _toDisplayString(_ctx.getTranslation('core.common.invalidDatesSelected')), 1))
            : (_openBlock(), _createElementBlock("div", _hoisted_2, _toDisplayString(_ctx.getTitleCaseTranslation('core.common.dateRange')), 1))
        ]),
        content: _withCtx(() => [
          _createVNode(_component_date_input, {
            modelValue: _ctx.modelValue.fromDate,
            "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.modelValue.fromDate) = $event)),
            label: _ctx.getTitleCaseTranslation('core.domain.dateFrom'),
            "default-time": "12:00:00",
            clearable: _ctx.modelValue.fromDate !== null
          }, null, 8, ["modelValue", "label", "clearable"]),
          _createVNode(_component_date_input, {
            modelValue: _ctx.modelValue.toDate,
            "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.modelValue.toDate) = $event)),
            label: _ctx.getTitleCaseTranslation('core.domain.dateTo'),
            "default-time": "12:00:00",
            clearable: _ctx.modelValue.toDate !== null
          }, null, 8, ["modelValue", "label", "clearable"])
        ]),
        _: 1
      }),
      _createVNode(_component_filter_criteria, null, {
        header: _withCtx(() => [
          _createTextVNode(_toDisplayString(_ctx.getTitleCaseTranslation('core.domain.locations')), 1)
        ]),
        content: _withCtx(() => [
          _createVNode(_component_form_multi_select, {
            modelValue: _ctx.modelValue.locations,
            "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => ((_ctx.modelValue.locations) = $event)),
            "search-by": "name",
            data: _ctx.locations
          }, null, 8, ["modelValue", "data"])
        ]),
        _: 1
      }),
      _createVNode(_component_filter_criteria, null, {
        header: _withCtx(() => [
          _createTextVNode(_toDisplayString(_ctx.getTitleCaseTranslation('core.domain.items')), 1)
        ]),
        content: _withCtx(() => [
          _createVNode(_component_dropdown_autocomplete_single_select, {
            "model-value": _ctx.modelValue.item?.name,
            "display-name": "name",
            "search-by": "name",
            "search-fn": _ctx.findItemsByName,
            onOnSelect: _ctx.selectItem
          }, null, 8, ["model-value", "search-fn", "onOnSelect"])
        ]),
        _: 1
      }),
      _createVNode(_component_filter_criteria, null, {
        header: _withCtx(() => [
          _createTextVNode(_toDisplayString(_ctx.getTitleCaseTranslation('core.domain.status')), 1)
        ]),
        content: _withCtx(() => [
          _createVNode(_component_form_multi_select, {
            modelValue: _ctx.modelValue.statuses,
            "onUpdate:modelValue": _cache[3] || (_cache[3] = ($event: any) => ((_ctx.modelValue.statuses) = $event)),
            "search-by": "name",
            data: _ctx.allStatuses
          }, null, 8, ["modelValue", "data"])
        ]),
        _: 1
      })
    ]),
    _: 1
  }, 8, ["filter-default", "filter-dirty", "onReset", "onSubmit"]))
}