
import {
    defineComponent, computed, PropType, toRefs,
} from 'vue';
import DateInput from '@/components/inputs/DateInput.vue';
import masterDataStore from '@/modules/master-data/store/MasterDataStore';
import { Formatter, getAllStatuses } from '@/domain/TransactionStatus';
import FormMultiSelect from '@/components/FormMultiSelect.vue';
import DropdownAutocompleteSingleSelect from '@/components/dropdown/DropdownAutocompleteSingleSelect.vue';
import FilteredTransactionSearch from '@/dtos/filters/FilteredTransactionSearch';
import AdvancedFilters from '@/components/filters/AdvancedFilters.vue';
import FilterCriteria from '@/components/filters/FilterCriteria.vue';
import useItemSearch from '@/composable/useItemSearch';
import { daysBack } from '@/functions/date';
import { StaticLocationType } from '@/domain/LocationType';
import { getTitleCaseTranslation, getTranslation } from '@/services/TranslationService';

export default defineComponent({
    name: 'transaction-advanced-filters',
    components: {
        DropdownAutocompleteSingleSelect,
        AdvancedFilters,
        DateInput,
        FilterCriteria,
        FormMultiSelect,
    },
    props: {
        modelValue: {
            type: Object as PropType<FilteredTransactionSearch>,
            default: () => new FilteredTransactionSearch({ fromDate: daysBack(15) }),
        },
        disabled: Boolean,
        filterDirty: Boolean,
        filterDefault: Boolean,
    },
    emits: ['update:modelValue', 'submit', 'reset'],

    setup(props, context) {
        const locations = masterDataStore.getInstance().locationStore.locations.filter((loc) => loc.type.staticTypeIdentifier !== StaticLocationType.BuiltIn);
        const allStatuses = getAllStatuses().map((x) => ({ name: Formatter.GetFriendlyValue(x), value: x }));

        const { findItemsByName, selectItem } = useItemSearch(toRefs(props).modelValue);

        const invalidDateRange = computed((): boolean => props.modelValue.fromDate !== null && props.modelValue.toDate !== null && props.modelValue.fromDate > props.modelValue.toDate);

        async function resetAllFilters() {
            context.emit('reset');
        }

        async function submit() {
            context.emit('submit');
        }

        return {
            locations,
            allStatuses,
            submit,
            selectItem,
            resetAllFilters,
            invalidDateRange,
            findItemsByName,
            getTitleCaseTranslation,
            getTranslation,
        };
    },
});
